import React from 'react';
import { useState } from 'react';
import styled from "styled-components";
import Logo from '../images/TAGim.png';
import axios from "axios";


const Footer = () => {

    const baseURL = "https://portal.tagimtech.com/tagim/api/tagim-tech/add";
    const regEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const [emailInput, setEmailInput] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [successResponse, setSuccessResponse] = useState(false);
    const [failedResponse, setFailedResponse] = useState(false);

    const validate = () => {
        var err = false;

        if (regEx.test(emailInput)) {
            err = false;
            setEmailError(false);
        } else {
            setEmailError(true);
        }

        return err;
    }

    const handleEmailChange = (e) => {
        setEmailInput(e.target.value);
    }

    const handelSubmit = (e) => {

        e.preventDefault();
        const err = validate();

        if (!err) {
            axios.post(baseURL, {
                email: emailInput
            })
                .then((response) => {
                    if (response.data.msg === "Success") {
                        setSuccessResponse(true);
                        setFailedResponse(false);
                        setEmailInput('');
                    } else {
                        setSuccessResponse(false);
                    }
                })
                .catch(function (error) {
                    if (error.message) {
                        setFailedResponse(true);
                        setSuccessResponse(false);
                    } else {
                        setFailedResponse(false);
                    }
                });
        }
    }


    return (
        <Main>
            <Body>
                <Card1>
                    <div>
                        We would love to hear from you:
                        <br />
                        Email:  <a href='mailto:info@tagimtech.com'> info@tagimtech.com</a>
                    </div>
                </Card1>

                <Card2>
                    <h1>Sign up to receive news {`&`} updates.</h1>
                    <div>
                        Enter your email here:*
                        <Form>
                            <input value={emailInput} type="email" onChange={handleEmailChange} error={emailError ? emailError : undefined} style={{ border: emailError ? '.1px solid red' : '.1px solid black' }} />
                            <input type="submit" onClick={handelSubmit} />
                        </Form>
                        <br />
                        {successResponse && (
                            <span>Thanks for submitting!</span>
                        )}
                        {failedResponse && (
                            <span>Something went wrong, try again later. </span>
                        )}
                    </div>
                </Card2>
            </Body>
        </Main>
    );
};

//-------------------------------------------------------------------------------------------

const Main = styled.div`
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    padding-bottom: 2rem;
    background: #FAFAFA;

    @media (max-width:1281px) {
        padding-bottom: 1rem;
    }
`;

const Body = styled.div`
    padding: 0 6rem 0 6rem;
    display: flex;
    text-align: center;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 2rem;

    @media (max-width:1281px) {
            padding: 0 1rem 0 1rem;
            flex-direction: column;
            margin-bottom: 1rem;
    }
`

const Card1 = styled.div`

     flex-basis:100%;
     background: #D3E109;
     display: flex;
     flex-direction: column;
     padding: 3rem;
     width: auto;
     color: white;

     div{
        font-size: 2em;
        line-height: 2em;
     }

     @media (max-width:1281px) {
            padding: 1rem;
            div{
                font-size: 1.5em;
            }
    }
`

const Card2 = styled.div`
     flex-basis:100%;
     width: auto;
     display: flex;
     flex-direction: column;
     padding: 0rem 2rem 0rem 2rem;

     h1{
        font-size: 2em;
        color: #dfdf38;
        padding: 1rem 0 1rem 0;
        line-height: 1.5em;
     }
     div{
        color: #989b74;
        font-size: 1.3em;
        line-height: 1.5em;
        padding-bottom: 2rem;
     }
     span{
        color: #55D9E7;
     }

     @media (max-width:1281px) {
        padding: 0rem 1rem 0rem 1rem;
        h1{
            font-size: 1.5em;
        }
        div{
            font-size: 1em;
        }
    }
`

const Form = styled.form`

   padding-top: 1rem;

   input[type=email]{
    border: .1px solid black;
    border-radius: 5%;
    height: 30px;
    padding: .5rem;
   }

   input:focus { 
        outline: none !important;
        border-color: #719ECE;
    }

   input[type=submit]{
    border: .1px solid black;
    border-radius: 5%;
    height: 30px;
    margin-left: 1rem;
    width: 90px;
    background: black;
    color: white;
    font-size: 1rem;
    cursor: pointer;
   }
   
`

export default Footer;