import React from 'react';
import styled from "styled-components";
import Cover from '../images/cover.png';
import TagLogo from '../images/tagim_logo.png';
import Details from '../components/details';
import Comp2 from '../components/comp2';
import Comp3 from '../components/comp3';
import Comp4 from '../components/comp4';
import Footer from '../components/footer';
import PrivacyPolicy from '../components/privacyPolicy';
import videoBg from '../images/shutterstock.mov';

const Home = () => {

    
    return (
        <div>
            <Background>
                {/* <img className='backgroundImg' src={Cover} alt="background" /> */}
                <video className='backgroundImg' src={videoBg} autoPlay loop muted />
                <Container>
                    <Logo>
                        <Image src={TagLogo} alt="logo" />
                    </Logo>
                    <Main>
                        <h1>Affordable {`&`} Reliable</h1>
                        <h1>Remote Livestock Monitoring</h1>
                    </Main>
                </Container>

            </Background>

            <Details />
            <Comp2 />
            <Comp3 />
            <Comp4 />
            <Footer />
            <PrivacyPolicy />

        </div>
    );
};


const Background = styled.div`
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    object-position: 50% 50%;
    padding: .2rem .4rem 1rem .4rem ;
    /* @media (max-width:1281px) {
        padding-bottom: 0;
        .backgroundImg{
            height: 10rem;
            width: 100%;
        }
    } */
`;

const Image = styled.img`
    height: 20rem;
    @media (max-width:1025px){
        height: 15rem;
    }
    @media (max-width:961px) {
        height: 12rem;
    }
    @media (max-width:641px) {
        height: 8rem;
    }
`;

const Logo = styled.div`
    margin-right: 30rem;
    @media (max-width:1025px) {
        margin-right: 2rem;
    }
`;

const Container = styled.div`
    position: absolute;
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	align-content: center;
    left: 0;
    right: 0;
    margin-top: 4rem;
    @media (max-width:1281px) {
        margin-top: 0;
    }
`;

const Main = styled.div`
    color: white;
    font-size: 2em;
    display: flex;
	flex-direction: column;
	align-items: center;
    @media (max-width:961px) {
        font-size: 1.5em;
    }
    @media (max-width:641px) {
        font-size: 1em;
    }
    @media (max-width:481px) {
        font-size: .6em;
    }
`;

export default Home;
