import React from 'react';
import styled from "styled-components";
import { Link } from 'react-router-dom';

const PrivacyPolicy = () => {

    return (
        <Main>
            <Body>
                <div>
                    <StyledLink to="/rider-app-policy"> For Privacy Policy </StyledLink>
                </div>
            </Body>
        </Main>
    );
};

//-------------------------------------------------------------------------------------------

const Main = styled.div`
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    background: #F5F5F5;
`;

const Body = styled.div`
    padding: 0 1rem 0 1rem;
    text-align: center;
    flex-direction: row;
    justify-content: center;

    div{
        font-family: 'Arial', sans-serif; 
        text-align: center;
        padding: 1rem;
        color:  #49c9e6;;
        font-size: 0.9rem;
        font-weight: bold;
        line-height: 1.5;
        overflow: hidden;
    }
`

const StyledLink = styled(Link)`
        text-align: center;
        padding: 1rem;
        font-size: 0.9rem;
        font-weight: bold;
        line-height: 1.5;
        overflow: hidden;
        text-decoration: none;
`

export default PrivacyPolicy;