import React from 'react';
import styled from "styled-components";
import Farmer from '../images/farmer.jpeg';
import Lab from '../images/lab.png';
import RD from '../images/r&d.jpg';

const Comp3 = () => {

    return (
        <Main>

            <Body>
                <Card1>
                    <h1>Value Added</h1>
                    <div>
                        Timely care, days prior to visual
                        presentation of symptoms, improves Cost of
                        Gain, supports judicious use of antibiotics,
                        enabling better animal welfare.
                    </div>
                </Card1>

                <Card2>
                    <h1>Work Force Efficiency</h1>
                    <div>
                        Care takers can trust in automatic checking {`&`} flagging
                        of sick animals focusing attention to pre-identified
                        animals of interest, avoiding late pulls {`&`} enhancing
                        their performance, with animals receiving earlier
                        interventions {`&`} better outcomes.
                    </div>

                    <h1>Count Cattle</h1>
                    <div>Automatic head count of inventory per pen, generating reports that are easily shareable, for more efficient asset verification.</div>
                </Card2>
            </Body>

            <Body>
                <Card3>
                    <div>
                        "TAGim<label>®</label> offers a different approach, which truly responds to the industry's needs."
                    </div>
                    <div>
                        Eyal Muller is the owner of Yanai Farms, in the North of Israel.
                        A rancher, with extensive experience in Israel, managing feedlots {'>'} 10,000 head,
                        Eyal has been using TAGim<label>®</label> on a daily basis for his beef cattle feedlot.
                    </div>
                </Card3>

                <Image src={Farmer} alt="img" />
            </Body>

            <Body>
                <Image src={Lab} alt="img" />
                <Card4>
                    <div>
                        TAGim<label>®</label> patented technology was developed {`&`} is manufactured by Medisim, a global leader of best-in-class,
                        affordable diagnostic medical devices, based on breakthrough technologies.
                        Harnessing over 25 years of expertise in human temperature measurement, in-house design-to-cost {`&`} mass production
                        of medical products marketed by US' leading mass retailers, yielded a unique solution for the industry's unmet need.
                        <br /><br />
                        For more information visit: <a href='http://www.medisim.co.il'>www.medisim.co.il</a>
                    </div>
                </Card4>
            </Body>

            <Body>
                <Card5>
                    <h1>Join the team</h1>
                    <div>
                        If you thrive on introducing innovation {`&`} have industry<br /> experience - we want to know you !
                        <br /><br />
                        {/* Please submit your CV here: */}
                    </div>
                </Card5>

                <Image src={RD} alt="img" />
            </Body>

        </Main>
    );
};

//-------------------------------------------------------------------------------------------

const Main = styled.div`
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    padding-bottom: 4rem;
    background: #FAFAFA;
`;

const Body = styled.div`
    padding: 0 6rem 0 6rem;
    display: flex;
    text-align: center;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 2rem;

    @media (max-width:1281px) {
        flex-direction: column;
        padding: 0 1rem 0 1rem;
    }
`

const Card1 = styled.div`

     flex-basis:100%;
     background: #D3E109;
     display: flex;
     flex-direction: column;
     text-align: left;
     padding: 3rem;
     width: auto;

     h1{
        font-size: 3em;
        padding-bottom: 2rem;
        line-height: 1.5em;
     }
     div{
        font-size: 1.5em;
        line-height: 1.5em;
     }

     @media (max-width:1281px) {
        text-align: center;
        padding: 0.5rem;

        h1{
            font-size: 2em;
            padding-bottom: 1rem;
        }
        div{
            font-size: 1.1em;
        }
    }
`
const Card2 = styled.div`
     flex-basis:100%;
     width: auto;
     display: flex;
     text-align: left;
     flex-direction: column;
     padding: 0rem 2rem 0rem 2rem;

     h1{
        font-size: 2em;
        color: #dfdf38;
        padding: 1rem 0 1rem 0;
        line-height: 1.5em;
     }
     div{
        color: #989b74;
        font-size: 1.3em;
        line-height: 1.5em;
        padding-bottom: 2rem;
     }

     @media (max-width:1281px) {
        text-align: center;
        padding: 0.5rem;

        h1{
            font-size: 2em;
            padding-bottom: 1rem;
        }
        div{
            font-size: 1.1em;
            padding-bottom: 1rem;
        }
    }
`

const Card3 = styled.div`
     flex-basis:100%;
     width: auto;
     display: flex;
     text-align: left;
     flex-direction: column;
     justify-content: center;
     padding: 4rem 4rem 4rem 2rem ;

     div{
        color: #989b74;
        font-size: 1.5em;
        line-height: 1.5em;
        padding-bottom: 2rem;
     }

     label{
        font-size: 0.7em;
        top: -0.7rem;
        position: relative;
    }

     @media (max-width:1281px) {
        text-align: center;
        padding: 0.5rem;

        div{
            font-size: 1.1em;
            padding-bottom: 1rem;
        }
    }
`

const Image = styled.img`
    flex-basis:100%;
    width: 300px;
    height: auto; 
    max-height: 30rem;

    @media (max-width:1281px) {
         width: 100%;
    }
`

const Card4 = styled.div`
     flex-basis:100%;
     width: auto;
     display: flex;
     text-align: left;
     flex-direction: column;
     justify-content: center;
     padding-left: 4rem;

     div{
        color: #989b74;
        font-size: 1.5em;
        line-height: 1.5em;
        padding-bottom: 2rem;
     }
     
     label{
        font-size: 0.7em;
        top: -0.7rem;
        position: relative;
    }

     
     @media (max-width:1281px) {
        text-align: center;
        padding: 0.5rem;

        div{
            font-size: 1.1em;
            padding-bottom: 1rem;
        }
    }
`
const Card5 = styled.div`
     flex-basis:100%;
     width: auto;
     display: flex;
     text-align: left;
     flex-direction: column;
     justify-content: center;
     padding: 4rem;

     h1{
        font-size: 3em;
        color: #dfdf38;
        padding-bottom: 2rem;
        line-height: 1.5em;
     }

     div{
        color: #989b74;
        font-size: 1.5em;
        line-height: 1.5em;
        padding-bottom: 2rem;
     }

     @media (max-width:1281px) {
        text-align: center;
        padding: 0.5rem;

        h1{
            font-size: 2em;
            padding-bottom: 1rem;
        }
        div{
            font-size: 1.1em;
        }
    }
`

export default Comp3;