import React from 'react';
import styled from "styled-components";
import Jricheson from '../images/Jrichesonn1.jpg';
import Guy from '../images/Guy.jpg';
import Christian from '../images/Christian.png';
import Wade from '../images/Wade.jpg';
import Yossi from '../images/yossi.jpg';
import John from '../images/John.jpg';
import Jerome from '../images/Jerome.jpg';
import Josh from '../images/Josh-Szasz.png';
import Matt from '../images/Matt-Quinn.jpg';

const Comp4 = () => {

    return (
        <div>
            <Main>
                <h1>Advisory Board</h1>

                <Body>
                    <Card>
                        <Image src={Jricheson} alt="img" />
                        <h1>John Richeson, Ph.D.</h1>
                        <div>Professor of Beef Cattle Feedlot Management at West Texas A{`&`}M University</div>
                    </Card>
                    <Card>
                        <Image src={Guy} alt="img" />
                        <h1>Dr. Guy Ellis</h1>
                        <div>Director of Veterinary Services at Cactus Feeders</div>
                    </Card>
                    {/* <Card>

                        <Image src={Tom} alt="img" />
                        <h1>Dr. Tom Portillo</h1>
                        <div>Consulting Veterinarian with Veterinary Research {`&`} Consulting Services</div>
                    </Card> */}
                </Body>
                <Body>
                    <Card>
                        <Image src={Wade} alt="img" />
                        <h1>Dr. Wade Taylor</h1>
                        <div>Consulting Veterinarian <br /> Production Animal Consultation</div>
                    </Card>
                    <Card>
                        <Image src={Josh} alt="img" />
                        <h1>Dr. Josh Szasz</h1>
                        <div>Staff Veterinarian <br /> Five Rivers Cattle Feeding</div>
                    </Card>
                    <Card>
                        <Image src={Matt} alt="img" />
                        <h1>Dr. Matthew Quinn</h1>
                        <div>Production/Nutrition Consultant <br /> With Feedlot Health Management Services</div>
                    </Card>
                </Body>
            </Main>

            <Main>
                <h1>Team</h1>

                <Body>
                    <Card>
                        <Image src={Jerome} alt="img" />
                        <h1>Jerome Price</h1>
                        <div>Technical Operations Manager</div>
                    </Card>
                    <Card>
                        <Image src={Yossi} alt="img" />
                        <h1>Yossi Wittman</h1>
                        <div>VP Business Development</div>
                    </Card>
                    <Card>
                        <Image src={John} alt="img" />
                        <h1>John Wilson</h1>
                        <div>President Medisim USA Inc.</div>
                    </Card>
                    <Card>
                        <Image src={Christian} alt="img" />
                        <h1>Christian Allen</h1>
                        <div>Customer Success Manager</div>
                    </Card>
                </Body>
            </Main>
        </div>
    );
};

//-------------------------------------------------------------------------------------------

const Main = styled.div`
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    padding-bottom: 2rem;
    background: #FAFAFA;
    h1{
        font-size: 4em;
    }

    @media (max-width:1281px) {
        padding-bottom: 0rem;
        h1{
            font-size: 2em;
            padding-bottom: 1rem;
        }
    }
`;

const Body = styled.div`
    display: flex;
    padding: 2rem;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    
    @media (max-width:1281px) {
        padding: 0;
    }
`

const Card = styled.div`
    display: flex;
    flex-direction: column;
    margin: 2rem;
    border: 1px solid #e2dfdf;
    height: auto;
    width: 20rem;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

    h1{
        padding: 2rem;
        font-size: 2em;
        color: #dfdf38;
    }
    div{
        white-space: initial;
        word-wrap: break-word;
        font-size: 1.5em;
        color: #989b74;
        padding: 0 2rem 3rem 2rem;
        line-height: 1.5em;
    }

    @media (max-width:1281px) {
        margin: 1rem;
        h1{
            padding: 1rem;
            font-size: 1.5em;
        }
        div{
            font-size: 1em;
            padding: 0 1rem 2rem 1rem;
        }
    }
`

const Image = styled.img`
    border-radius: 50%;
    width: 20rem;
    height: 20rem;
    padding: 1rem;

    @media (max-width:1281px) {
        width: 100%;
        height: 20rem;
    }
`

export default Comp4;