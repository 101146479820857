
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import GlobalStyles from './styles/GlobalStyles';

import Home from './pages/Home';
import RiderAppPolicy from './pages/Rider-App-Policy'

function App() {
  return (
    <>

      <Router>
        <GlobalStyles />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/rider-app-policy" element={<RiderAppPolicy />} />
        </Routes>
      </Router>

    </>
  );
}

export default App;
