import { createGlobalStyle } from 'styled-components';


const GlobalStyles = createGlobalStyle`

    *{
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'Montserrat' , sans-serif;
        font-weight: 100;
        /* background: #FAFAFA; */
    };

`

export default GlobalStyles;