import React from 'react';
import styled from "styled-components";
import Pic1 from '../images/tag App and screens.png';
import Cattle from '../images/CattleWithCircles.png';

const Details = () => {

    return (
        <Main>
            <Container>
                <TextContainer>
                    <Text>
                        <h1>Enabling use of</h1>
                        <h1>livestock Remote Monitoring</h1>
                        <TextArea>
                            TAGim<label>®</label> Precision Livestock Farming system
                            automatically detects {`&`} alerts of livestock
                            in suspected ill health, for earlier {`&`} more
                            effective intervention.
                        </TextArea>
                    </Text>

                </TextContainer>

                <Image src={Pic1} alt="img" />

            </Container>

            <Container>
                <Image src={Cattle} alt="img" />
                <TextContainer>
                    <Text>
                        <h1>Enabling use of</h1>
                        <h1>livestock Remote Monitoring</h1>
                        <TextArea>
                            TAGim<label>®</label> consists of smart ear tags, wireless
                            communications, analytics dashboards {`&`} a
                            unique pen rider App.
                        </TextArea>
                        <TextArea>
                            TAGim<label>®</label> offers fully automated, continuos
                            monitoring of temperature {`&`} feeding for
                            each individual animal {`&`} the entire herd.
                        </TextArea>
                    </Text>
                </TextContainer>
            </Container>

        </Main>
    );
};

//-------------------------------------------------------------------------------------------

const Main = styled.div`
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    padding-bottom: 2rem;
    background: #FAFAFA;

    @media (max-width:1281px) {
    justify-items: center;
    }
`;

const Container = styled.div`
    padding: 0 6rem 0 6rem;
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: row;

    @media (max-width:1281px) {
         padding: 0.5rem 1rem 0.5rem 1rem;
         flex-direction: column;
    }
    
`

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    object-fit: cover;
    flex-basis:100%;
`

const Text = styled.div`
    padding: 2rem;
    text-align: left;
    h1{
        color: #dfdf38;
    }
    @media (max-width:1281px) {
        text-align: center;
    }
`

const TextArea = styled.div`
    font-size: 1.5em;
    padding-top: 2rem;
    padding-right: 2rem;
    color: #989b74;
    word-wrap: break-word;
    line-height: 1.5em;

    label{
        font-size: 0.7em;
        top: -0.7rem;
        position: relative;
    }

    @media (max-width:1281px) {
        padding-top: 1.5rem;
        padding-right: 0rem;
        font-size: 1em;
    }
`

const Image = styled.img`
    flex-basis:100%;
    width: 300px;
    height: auto; 

    @media (max-width:1281px) {
        width: 100%;
        height: auto; 
    }
`

export default Details;