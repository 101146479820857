import React, { useState, useLayoutEffect } from 'react';
import axios from "axios";
import styled from "styled-components";

const RiderAppPolicy = () => {


    const baseURL = "https://portal.tagimtech.com/tagim/api/tagim-tech/unsubscribe";
    const regEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const [emailInput, setEmailInput] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [successResponse, setSuccessResponse] = useState(false);
    const [failedResponse, setFailedResponse] = useState(false);

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    const validate = () => {
        var err = false;

        if (regEx.test(emailInput)) {
            err = false;
            setEmailError(false);
        } else {
            setEmailError(true);
        }

        return err;
    }

    const handleEmailChange = (e) => {
        setEmailInput(e.target.value);
    }

    const handelSubmit = (e) => {

        e.preventDefault();
        const err = validate();

        if (!err) {
            axios.post(baseURL, {
                email: emailInput
            })
                .then((response) => {
                    console.log(response.data.msg);
                    if (response.data.msg === "UnSubscription Successfully") {
                        setSuccessResponse(true);
                        setFailedResponse(false);
                        setEmailInput('');
                    } else {
                        setSuccessResponse(false);
                        setFailedResponse(true)
                    }
                })
                .catch(function (error) {
                    if (error.message) {
                        setFailedResponse(true);
                        setSuccessResponse(false);
                    } else {
                        setFailedResponse(false);
                    }
                });
        }
    }


    return (
        <Main>
            <Header>
                <h1> Privacy Policy </h1>
            </Header>
            <Section>
                <h4> Rider - App </h4>
                <div>
                    <b className='boldText'>Brought to you by <a href="https://www.medisim.co.il">www.medisim.co.il</a> (Developer - MosheS)</b><br /><br />
                    Pen Riders are anticipated to use the App while riding a horse or while walking at the feedlot, therefore physical aspects have been taken in consideration.
                    The user interface is simple and clear - the Pen Rider should be able to receive all information needed from a single look at the screen.
                    <br /><br />
                    The purpose of the App is to assist the Pen Rider in daily tasks:<br />
                    <p>•	To receive lists of tasks</p>
                    <p2>	Automatically created by TAGIM system.</p2><br />
                    <p2>	Manually assigned by the Feedlot Manager.</p2> <br /><br />

                    <p>•	To search a specific animal</p>
                    <p2>	View detailed statistics of a specific animal.</p2><br />
                    <p2>	View task information.</p2><br /><br />

                    <p>•	To report & add new tasks</p>
                    <p2>	Animal health - abnormal animals; animal out of the pen; etc.</p2><br />
                    <p2>	Maintenance - hardware; and faults in the pen.</p2><br /><br />

                    <p>•	To communicate between team members and request help if needed</p>
                    <p2>	Pen Rider-to-Pen Rider.</p2><br />
                    <p2>	Pen Rider-to-Manager.</p2><br /><br />

                    <p>•	 Filter tasks – Rider App will be able to reorder tasks</p>
                    <p2>	By location.</p2><br />
                    <p2>	By pen.</p2><br /><br />
                    <p>•	Review self-performance</p>
                </div>
            </Section>

            <Section>
                <h4> Privacy Policy </h4>
                <div>
                    We collect limited personal data for website functionality. Information is used to enhance user experience and support.<br />
                    Your data is not shared with third parties without consent. Non-personal data may be used for analytics and marketing.<br />
                    Cookies are used to improve website performance. Users can manage cookie preferences in browser settings.<br />
                    Parental consent is required for children under 13. This policy may be updated periodically; review for changes.
                    <br /><br />
                    If you have any inquiries regarding our Privacy Policy, please drop us an email to <a href="mailto:support@tagimtech.com">Support@tagimtech.com</a>.<br />
                    For more information about our company, visit <a href="https://www.tagimtech.com">www.tagimtech.com</a>.
                </div>


                <Form>
                    <div>
                        <p> If you wish to unsubscribe and delete your data, please insert your email and click the button below.</p>
                        <input value={emailInput} type="email" onChange={handleEmailChange} error={emailError ? emailError : undefined} style={{ border: emailError ? '.1px solid red' : '.1px solid black' }} />
                        <input type="submit" value="Unsubscribe" onClick={handelSubmit} />
                    </div>
                </Form>
                <br />
                {successResponse && (
                    <span>Your request has been accepted!</span>
                )}
                {failedResponse && (
                    <span>Something went wrong!</span>
                )}
            </Section>
        </Main >
    )
}


const Main = styled.div`
    font-size: 2em;
    display: flex;
	flex-direction: column;
	align-items: center;
    overflow: hidden;
    
    @media (max-width:961px) {
        font-size: 1.5em;
    }
    @media (max-width:641px) {
        font-size: 1em;
    }
    @media (max-width:481px) {
        font-size: .6em;
    }
`;

const Header = styled.div`
  background: linear-gradient(to bottom, #0f79d6, #2e9fcc);
  color: white;
  padding: 2rem 0 2rem 0;
  text-align: center;
  width: 100%;

  h1{
    font-weight: bold;
    font-size: 3rem;
  }

  @media (max-width:1024px) {
    h1{
        font-size: 2.5rem;
    }
  }
`

const Section = styled.div`
    padding: 0 2rem 0 2rem;
    margin:  4rem 0rem 4rem 40% ;
    flex-direction: row;
    width: 100%;

    h4{
        font-size: 1.8rem;
        font-weight: bold;
        display: inline-block;
        border-bottom: 0.13rem solid #111111;
    }

    div{
        font-family: 'Arial', sans-serif; 
        white-space: initial;
        word-wrap: break-word;
        font-size: 1rem;
        padding: 2rem 4rem 0rem 0rem;
        text-align: left;
        line-height: 1.5em;
        width: 50%;
  }

    p{
        padding-left: 2rem;
    }
    p2{
        padding-left: 4rem;
    }
    .boldText {
    font-weight: bold;
    }
    .boldText a {
    font-weight: bold;
    }

    @media (max-width:1024px) {
        margin: 1rem ;
        padding-right: 1rem;
        
        h4{
            font-size: 1rem;
            border-bottom: 0.12rem solid #111111;
        }
        div{
            width: 100%;
            font-size: 0.7rem;
        }
        p{
        padding-left: 1rem;
        }
        p2{
            padding-left: 2rem;
        }
    }
 
`

const Form = styled.form`

    p{
        font-size: 1.1rem;
        padding: 0.5rem;
    }
    div{
        text-align: center;
        flex-direction: row;
        justify-content: center;
    }

   input[type=email]{
    border: .1px solid black;
    border-radius: 5%;
    height: 1.5rem;
    padding: .5rem;
   }

   input:focus { 
        outline: none !important;
        border-color: #719ECE;
    }

   input[type=submit]{
    border: .1px solid black;
    border-radius: 5%;
    height: 1.5rem;
    margin-left: 1rem;
    width: 90px;
    background: black;
    color: white;
    font-size: 0.8rem;
    cursor: pointer;
   }

   @media (max-width:1024px) {
    p{
        font-size: 0.8rem;
    }
    input[type=email]{
        height: 1.2rem;
    }
    input[type=submit]{
        margin: 0.5rem;
        height: 1.2rem;
    }
   }
   
`

export default RiderAppPolicy;
