import React from 'react';
import styled from "styled-components";

const Comp2 = () => {

    return (
        <Main>
            <Header>
                <h1>Why TAGim<label>®</label> ?</h1>
            </Header>
            <Body>
                <Card>
                    <h1>Cost-Effective</h1>
                    <div>  Monitoring individual animals,
                        with an inexpensive, disposable
                        ear tag for an attractive ROI to
                        enable wide adoption.
                    </div>
                </Card>

                <Card>
                    <h1>Pen-Rider App</h1>
                    <div>
                        <strong>TAGim<label>®</label></strong> pen rider App provides
                        the necessary tools to efficiently
                        locate the suspected animals in
                        the pen.
                    </div>
                </Card>

                <Card>
                    <h1>Visual ID</h1>
                    <div>
                        <strong>TAGim<label>®</label></strong> smart tags include
                        standard visual numbering,
                        negating cost of visual ID tags,
                        reducing infections {`&`} workload.
                    </div>
                </Card>

            </Body>
        </Main>
    );
};

//-------------------------------------------------------------------------------------------

const Main = styled.div`
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    padding-bottom: 2rem;
    background: #FAFAFA;
`;

const Header = styled.div`
    padding-top: 4rem;
    font-size: 2em;

    label{
        font-size: 0.7em;
        top: -1rem;
        position: relative;
    }

    @media (max-width:1281px) {
          padding: 0 1rem 0 1rem;
          font-size: 1.2em;
    }
`
const Body = styled.div`
    display: flex;
    padding: 2rem;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;

    @media (max-width:1281px) {
          padding: 0;
    }
`


const Card = styled.div`
    display: flex;
    flex-direction: column;
    margin: 2rem;
    border: 1px solid #e2dfdf;
    height: auto;
    width: 25rem;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

    h1{
        padding: 2rem;
        font-size: 2.5em;
        color: #dfdf38;
    }
    div{
        white-space: initial;
        word-wrap: break-word;
        font-size: 1.5em;
        color: #989b74;
        padding: 0 2rem 3rem 2rem;
        text-align: left;
        line-height: 1.5em;
    }
    label{
        font-size: 0.7em;
        top: -0.6rem;
        position: relative;
    }

    @media (max-width:1281px) {
        margin: 1.5rem;
        text-align: center;
          h1{
             padding: 1rem;
             font-size: 2em;
          }

          div{
            font-size: 1.1em;
            text-align: center;
          }
    }
`

export default Comp2;